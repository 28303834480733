import React from 'react';
import { bool } from 'prop-types';
import { Flex, Link, VisuallyHidden } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

import SiteLogo from '@assets/logo.svg';

const Logo = () => (
  <Flex alignItems="center" position="relative" zIndex="2">
    <Link as={GatsbyLink} to="/" h="100%">
      <VisuallyHidden>Dicks Sporting Goods</VisuallyHidden>
        <div><SiteLogo /></div>
    </Link>
  </Flex>
);

export default Logo;

Logo.defaultProps = {
  invert: false
};

Logo.propTypes = {
  invert: bool
};
